import React from "react";
import "./pageloader.scss"
import Loader from "./Loader";
 

export const PageLoader: React.FC = () => {

  return (
    <div className="page_loader">
      <Loader/>
    </div>
  );
};
