import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"

export type NavButtonState = {
    checked: boolean,
    disabled: boolean,
    path: string,
}

const initialState: NavBarState = {
    pages: {
        home: {
            checked: false,
            disabled: false,
            path: "/home"
        },
        activities: {
            checked: false,
            disabled: false,
            path: "/activities"
        },
        screener: {
            checked: false,
            disabled: false,
            path: "/screener"
        },
        builder: {
            checked: false,
            disabled: false,
            path: "/builder"
        },
        portfolio: {
            checked: false,
            disabled: false,
            path: "/portfolio"
        },
        reports: {
            checked: false,
            disabled: false,
            path: "/reports"
        },
        insights: {
            checked: false,
            disabled: false,
            path: "/insights"
        },
        alerts: {
            checked: false,
            disabled: false,
            path: "/alerts"
        }
    },
    utils: {
        calender: {
            checked: false,
            disabled: false,
            path: "/home"
        },
        calculator: {
            checked: false,
            disabled: false,
            path: "/home"
        }
    },
    system: {
        themeswitch: {
            checked: false,
            disabled: false,
            path: "/home"
        },
        emails: {
            checked: false,
            disabled: false,
            path: "/home"
        },
        notifications: {
            checked: false,
            disabled: false,
            path: "/home"
        },
        preferences: {
            checked: false,
            disabled: false,
            path: "/preferences"
        },
        logout: {
            checked: false,
            disabled: false,
            path: "/home"
        }
    }
}

export interface NavBarState {
    pages: {
        home: NavButtonState,
        activities: NavButtonState,
        screener: NavButtonState,
        builder: NavButtonState,
        portfolio: NavButtonState,
        reports: NavButtonState,
        insights: NavButtonState,
        alerts: NavButtonState
    }
    utils: {
        calender: NavButtonState,
        calculator: NavButtonState
    }
    system: {
        themeswitch: NavButtonState,
        emails: NavButtonState,
        notifications: NavButtonState,
        preferences: NavButtonState,
        logout: NavButtonState
    }
}

const resetChecked = (state:NavBarState) => {
    state.pages.activities.checked = false;
    state.pages.builder.checked = false;
    state.pages.portfolio.checked = false;
    state.pages.reports.checked = false;
    state.pages.insights.checked = false;
    state.pages.screener.checked = false;
    state.system.preferences.checked = false;
}

export const navBarSlice = createSlice({
    name: "navbar",
    initialState,
    reducers: {
        changeTarget: (state, action: PayloadAction<string>) => {

        },
        navigate: (state, action: PayloadAction<string>) => {
            switch (action.payload) {
                case "activities":
                    if(!state.pages.activities.disabled){
                        resetChecked(state);
                        state.pages.activities.checked = true
                    }
                    break;
                case "screener":
                    if(!state.pages.screener.disabled){
                        resetChecked(state);
                        state.pages.screener.checked = true
                    }
                    break;
                case "builder":
                    if(!state.pages.builder.disabled){
                        resetChecked(state);
                        state.pages.builder.checked = true
                    }
                    break;
                case "portfolio":
                    if(!state.pages.portfolio.disabled){
                        resetChecked(state);
                        state.pages.portfolio.checked = true
                    }
                    break;
                case "reports":
                    if(!state.pages.reports.disabled){
                        resetChecked(state);
                        state.pages.reports.checked = true
                    }
                    break;
                case "insights":
                    if(!state.pages.insights.disabled){
                        resetChecked(state);
                        state.pages.insights.checked = true
                    }
                    break;
                case "preferences":
                    if(!state.system.preferences.disabled){
                        resetChecked(state);
                        state.system.preferences.checked = true
                    }
                    break; 
                default :
                    break;
            }
        }
    }
});

export const selectNavState = (state: RootState) => state.navbar;
export const {changeTarget, navigate} = navBarSlice.actions;
export default navBarSlice.reducer;