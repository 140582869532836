import { useAuth0 } from "@auth0/auth0-react";
import { AuthenticationGuard } from "./security/AuthenticationGaurd";
import { Route, Routes } from "react-router-dom";
import { CallbackPage } from "./security/callback-page";
import './App.scss';
import { PageLoader } from "./components/loaders/PageLoader";
import { useAppSelector } from "./app/hooks";
import { createContext, useContext, useEffect } from "react";
import ActivitiesPage from "./views/activities/ActivitiesPage";
import Shell from "./shell/Shell";
import Components from "./views/components/Components";
import { getSocket, setGetToken } from "./app/socket";
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import SampleActivity from "./views/math/Math";

function App() {

  const { isLoading, getAccessTokenSilently } = useAuth0();


  if (isLoading) {
    return (
      <div className="app">
        <PageLoader />
      </div>
    );
  } else {
    setGetToken(getAccessTokenSilently);
      Promise.all([
      getSocket("activitymanager").then((sock) => {
          console.log("activitymanager created")
      }),
      getSocket("controller").then((sock) => {
          console.log("controller created")
      }),
      getSocket("configurator").then((sock) => {
          console.log("configurator created")
      }),
      getSocket("useragent").then((sock) => {
          console.log("useragent created")
      })]).then(
        ()=>toast('🦄 all services connected !', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
          })
      )
  }

  let darkMode = window.localStorage.getItem('dark-mode') === 'true' ? "dark-mode" : ""
  return (
    <div className={"app " + darkMode} id="app">

      <Shell>
        <Routes>
          <Route path="/" element={<AuthenticationGuard component={ActivitiesPage} key={0} />} />
          {/* ------------------ */}
          <Route
            path="/activities"
            element={<AuthenticationGuard component={ActivitiesPage} key={1} />}
          />
          <Route
            path="/components"
            element={<AuthenticationGuard component={Components} key={2} />}
          />
          <Route
            path="/math"
            element={<AuthenticationGuard component={ActivitiesPage} key={2} />}
          />


          {/*
          <Route
            path="/screener"
            element={<AuthenticationGuard component={ScreenerPage} key={3} />}
          />
          <Route
            path="/builder"
            element={<AuthenticationGuard component={BuilderPage} key={4} />}
          />
          <Route
            path="/portfolio"
            element={<AuthenticationGuard component={PortfolioPage} key={5} />}
          />
          <Route
            path="/reports"
            element={<AuthenticationGuard component={ReportsPage} key={6} />}
          />
          {/* ------------------ 
          <Route path="/profile"
            element={<AuthenticationGuard component={ProfilePage} key={7} />}
          />
          <Route path="/preferences"
            element={<AuthenticationGuard component={PreferencePage} key={8} />}
          /> */}
          <Route path="/callback" element={<CallbackPage />} />
          {/* <Route path="*" element={<NotFoundPage />} /> */}
        </Routes>
      </Shell>
      <ToastContainer />
    </div>
  );
}
export default App;
