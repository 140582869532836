import { useCallback, useState } from 'react';
import './shell.scss';
import shellContext from './ShellContext';

export default function NavBar() {

  const [navWidth, setNavWidth] = useState(200);
  const [navContent, setNavContent] = useState(<></>);
  const [navHeader, setNavHeader] = useState(<></>);
  const [navFooter, setNavFooter] = useState(<></>);

  shellContext.registerNavStates(setNavHeader,setNavContent,setNavFooter);

  const handleMouseDown = () => {
    document.addEventListener("mouseup", handleMouseUp, true);
    document.addEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseUp = () => {
    document.removeEventListener("mouseup", handleMouseUp, true);
    document.removeEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseMove = useCallback((e: { clientX: number; }) => {
    const newWidth = e.clientX - document.body.offsetLeft;
    if (newWidth > 0 && newWidth < 500) {
      setNavWidth(newWidth);
    }
  }, []);

  const handleTouchStart = () => {
    document.addEventListener("touchend", handleTouchEnd, true);
    document.addEventListener("touchmove", handleTouchMove, true);
  };

  const handleTouchEnd = () => {
    document.removeEventListener("touchend", handleTouchEnd, true);
    document.removeEventListener("touchmove", handleTouchMove, true);
  };

  const handleTouchMove = useCallback((e: TouchEvent) => {
    const newHeight = e.touches[0].clientX - document.body.offsetLeft;
    if (newHeight > 0 && newHeight < 900) {
      setNavWidth(newHeight);
    }
  }, []);


  return (
    <div className="navbar">

      <div className='container' style={{ width: `${navWidth}px` }}>
        <div className="nav-header">
          {navHeader}
        </div>
        <div className="nav-content">
          {navContent}
        </div>
        <div className="nav-footer">
          {navFooter}
        </div>
      </div>
      <div className='handle'
        onMouseDown={e => handleMouseDown()} onTouchStart={e => handleTouchStart()}>
      </div>
    </div>
  )
}
