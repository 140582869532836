import { useState } from 'react';
import './toggle.scss'

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { changeMode, selectTheme } from '../../state/theme/themeSlice';


export function ToggleSwitchSquare() {
    return (
        <label className="switch">
            <input type="checkbox" />
            <span className="slider"></span>
        </label>
    )
}

export function ToggleSwitchRound({ onChange, checked }: { checked: boolean, onChange: (e: React.ChangeEvent<HTMLInputElement>) => void }) {

    const [state, setState] = useState(checked);

    const handler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked)
            setState(false);
        else setState(true);
        onChange(e);
    }

    return (
        <label className="switch" >
            <input type="checkbox" onChange={e => handler(e)} checked={state} />
            <span className="slider round"></span>
        </label>
    )
}


export function ToggleTheme() {
    const themestate = useAppSelector(selectTheme);
    const dispatch = useAppDispatch();

    return (
        <label className="theme-switch" >
            <input type="checkbox" checked={themestate.isDarkMode} onClick={()=>dispatch(changeMode())} />
            <span className="theme-switch-slider"></span>
        </label>
    )
}
