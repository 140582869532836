import React, { useState } from 'react'

import { Intent } from '../../constants';
import './modal.scss'
import Draggable from 'react-draggable';

export type BasicModal = {
  title: string,
  intent: Intent,
  position?: { x: number, y: number }
}

export type ModalState = {
  activeDrags: number,
  deltaPosition: {
    x: number, y: number
  },
  controlledPosition: {
    x: number, y: number
  }
};

export default function BasicModal(
  props: {
    children?: React.ReactElement<any, string | React.JSXElementConstructor<any>>,
    defaultPosition?: { x: number, y: number },
    header ?: React.ReactElement<any, string | React.JSXElementConstructor<any>>,
    footer ?: React.ReactElement<any, string | React.JSXElementConstructor<any>>
  }) {

  const initialState: ModalState = {
    activeDrags: 0,
    deltaPosition: {
      x: 0, y: 0
    },
    controlledPosition: {
      x: -400, y: 200
    }
  };

  const [state, setState] = useState(initialState);


  const onStart = () => {
    setState({ ...state, activeDrags: ++state.activeDrags });
  };

  const onStop = () => {
    setState({ ...state, activeDrags: --state.activeDrags });
  };
  const dragHandlers = { onStart: onStart, onStop: onStop };


  return (
    <Draggable {...dragHandlers} defaultPosition={props.defaultPosition}>
      <div className='model' style={{ zIndex: 1000, position: "absolute", height: "auto", width: "auto" }}>
        <div className="model-header">
          {props.header ? props.header : ""}
        </div>
        <div className="model-content">
          {props.children}
        </div>
        <div className="model-footer">
          {props.footer ? props.footer : ""}
        </div>
      </div>
    </Draggable>
  )
}
