import { useCallback } from 'react';
import './shell.scss';
import React from 'react';

export default function Drawer() {
  const [drawerHeight, setDrawerHeight] = React.useState(200);


  const handleMouseDown = () => {
    document.addEventListener("mouseup", handleMouseUp, true);
    document.addEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseUp = () => {
    document.removeEventListener("mouseup", handleMouseUp, true);
    document.removeEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseMove = useCallback((e: { clientY: number; }) => {
    const newHeight = window.innerHeight - e.clientY;  
    if (newHeight > 0 && newHeight < 900) {
      setDrawerHeight(newHeight);
    }
  }, []);

  const handleTouchStart = () => {
    document.addEventListener("touchend", handleTouchEnd, true);
    document.addEventListener("touchmove", handleTouchMove, true);
  };

  const handleTouchEnd = () => {
    document.removeEventListener("touchend", handleTouchEnd, true);
    document.removeEventListener("touchmove", handleTouchMove, true);
  };

  const handleTouchMove = useCallback((e: TouchEvent) => {
    const newHeight = window.innerHeight - e.touches[0].clientY;  
    if (newHeight > 0 && newHeight < 900) {
      setDrawerHeight(newHeight);
    }
  }, []);

  return (
    <div className="drawer">
      <div className='handle' 
      onMouseDown={e => handleMouseDown()} onTouchStart={e => handleTouchStart()}></div>
      <div className="container" style={{ height: `${drawerHeight}px` }}>

      </div>
    </div>
  )
}
