import React, { useState } from 'react'
import BasicModal from "../../components/modals/BasicModal";
import { Info, InputControl, SelectStrategy } from './Activity';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';


export default function NewActivityDialog() {

    const [tab, setTab] = useState(0);

    let onSelect = (index : number, lastIndex: number)=>{
        setTab(index);
    }

    return (
        <div className="new-activity-dialog" style={{ position: "absolute" }}>
            <BasicModal defaultPosition={
                {
                    x: (window.innerWidth / 3),
                    y: (window.innerHeight / 3)
                }}
                header = {<>New Activity</>}
                footer = {<>{
                    tab==0?"Step 1/3 : Basic info":
                    tab==1?"Step 2/3 : Configure Input control":
                    tab==2?"Step 3/3 : Select strategy to apply":""
                    }</>}
                >
                <div className='new-activity-dialog__content'>

                    <Tabs onSelect={onSelect} selectedIndex={tab}>
                        <TabList>
                            <Tab>info</Tab>
                            <Tab>input</Tab>
                            <Tab>strategy</Tab>
                        </TabList>

                        <TabPanel>
                            <Info/>
                        </TabPanel>
                        <TabPanel>
                            <InputControl/>
                        </TabPanel>
                        <TabPanel>
                            <SelectStrategy/>
                        </TabPanel>
                    </Tabs>
                </div>
            </BasicModal>
        </div>
    )
}
