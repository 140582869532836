import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../state/counter/counterSlice';
import navBarReducer from '../state/navbar/navBarSlice';
import themeReducer from '../state/theme/themeSlice';

export const store = configureStore({
  reducer: {
    navbar : navBarReducer,
    counter: counterReducer,
    theme: themeReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
