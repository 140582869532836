import { ILogo } from "../../components/icon/Icon";
import shellContext from "../../shell/ShellContext";
import "./components.scss";


function ComponentNav() {
  return <div className="component-nav">
    <ul>
      <li><a href="#buttons">Bottons</a></li>
      <li><a href="#icons">Icons</a>
        <ul>
          <li><a href="#icon-size">size</a></li>
          <li><a href="#icon-varient">varient</a></li>
          <li><a href="#icon-intent">intent</a></li>
        </ul> </li>
      <li><a href="#navigation">Navigation</a>
        <ul>
          <li><a href="#breadcrumbs">breadcrumbs</a></li>
          <li><a href="#dropdown">dropdown</a></li>
          <li><a href="#menus">menus</a></li>
          <li><a href="#pagination">pagination</a></li>
          <li><a href="#steps">steps</a></li>
        </ul>
      </li>

      <li><a href="#data-entry">Data Entry</a>
        <ul>
          <li><a href="#autocompletes">autocomplete</a></li>
          <li><a href="#checkboxes">checkbox</a></li>
          <li><a href="#cascaders">cascader</a></li>
          <li><a href="#datepickers">datepicker</a></li>
          <li><a href="#inputs">input</a></li>
          <li><a href="#numberinputs">number input</a></li>
          <li><a href="#checkboxes">checkbox</a></li>
          <li><a href="#radio">radio</a></li>
          <li><a href="#switch">switch</a></li>
          <li><a href="#selects">selects</a></li>
          <li><a href="#treeselects">treeselects</a></li>
        </ul>
      </li>
      <li><a href="#data-display">Data Display</a>
        <ul>
          <li><a href="#avatars">avatars</a></li>
          <li><a href="#badges">badges</a></li>
          <li><a href="#collapses">collapses</a></li>
          <li><a href="#cards">cards</a></li>
          <li><a href="#lists">lists</a></li>
          <li><a href="#trees">trees</a></li>
          <li><a href="#tooltips">tooltips</a></li>
          <li><a href="#tags">tags</a></li>
          <li><a href="#tables">tables</a></li>
          <li><a href="#tabss">tabs</a></li>
        </ul>
      </li>
      <li><a href="#feedback">Feedback</a>
        <ul>
          <li><a href="#alerts">alerts</a></li>
          <li><a href="#popup-modals">popup-modals</a></li>
          <li><a href="#popup-confirm">popup-confirm</a></li>
          <li><a href="#popup-action">popup-action</a></li>
          <li><a href="#notifications">notifications</a></li>
          <li><a href="#progresses">progresses</a></li>
          <li><a href="#spinners">spinners</a></li>
          <li><a href="#skeletons">skeletons</a></li>
        </ul>
      </li>
    </ul>
  </div>
}

function ComponentHeader() {
  return <p className="components-nav-header">MC-Components</p>
}

export default function Components() {

  shellContext.setNavContent(<ComponentNav />)
  shellContext.setNavHeader(<ComponentHeader />)
  shellContext.setNavFooter(<ComponentHeader />)

  return (
    <div className='components'>
      <h1>UI-Components</h1>

      <div className="buttons categories" id="buttons">
        <h3>Buttons</h3>
        <div className="contents fx-row ">
          <button className="">button</button>
          <button className="round">rounded</button>
          <button className="circle">circle</button>
          <button className="square">square</button>
        </div>
      </div>

      <div className="icons categories" id="icons">
        <h3>Icons</h3>
        <div className="contents">
          <h5 id="icon-size">size</h5>
          <ILogo size="x-small" />
          <ILogo size="small" />
          <ILogo size="medium" />
          <ILogo size="large" />
          <ILogo size="x-large" />
          <h5 id="icon-varient">varient</h5>
          <ILogo varient={"primary"} />
          <ILogo varient={"active"} />
          <ILogo varient={"disabled"} />
          <ILogo varient={"hidden"} />
          <ILogo varient={"display-none"} />
          <ILogo varient={"blink"} />
          <h5 id="icon-intent">intent</h5>
          <ILogo intent={"success"} />
          <ILogo intent={"info"} />
          <ILogo intent={"alert"} />
          <ILogo intent={"warn"} />
          <ILogo intent={"error"} />
        </div>
      </div>

      <div className="navigation categories" id="navigation">
        <h3>Navigation</h3>

        <div className="breadcrumbs section" id="breadcrumbs">
          <h3>Breadcrumbs</h3>
          <div className="contents">
            <nav>
              <ol className="breadcrumb">
                <li><a href="#0">Home</a></li>
                <li><a href="#0">Gallery</a></li>
                <li><a href="#0">Web</a></li>
                <li className="current"><em>Project</em></li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="dropdown section" id="dropdown">
          <h3>Dropdown</h3>
          <div className="contents">
            <div className="mc-dropdown">
              <button>Dropdown</button>
              <div>
                <a href="#">Link 1</a>
                <a href="#">Link 2</a>
                <a href="#">Link 3</a>
              </div>
            </div>
          </div>
        </div>
        <div className="menus section" id="menus">
          <h3>Menu</h3>
          <div className="contents">

          </div>
        </div>
        <div className="pagination section" id="pagination">
          <h3>Pagination</h3>
          <div className="contents">

          </div>
        </div>
        <div className="steps section" id="steps">
          <h3>Steps</h3>
          <div className="contents">
            <nav>
              <ol className="multi-steps">
                <li><a href="#0">Home</a></li>
                <li><a href="#0">Gallery</a></li>
                <li><a href="#0">Web</a></li>
                <li className="current"><em>Project</em></li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="data-entry categories" id="data-entry">

        <h3>Data Entry</h3>

        <div className="autocompletes section" id="autocompletes">
          <h3>Autocomplete</h3>
          <div className="contents">

          </div>
        </div>
        <div className="checkboxes section" id="checkboxes">
          <h3>Checkbox</h3>
          <div className="contents">

          </div>
        </div>
        <div className="cascaders section" id="cascaders">
          <h3>Cascader</h3>
          <div className="contents">

          </div>
        </div>
        <div className="datepickers section" id="datepickers">
          <h3>Date picker</h3>
          <div className="contents">

          </div>
        </div>
        <div className="inputs section" id="inputs">
          <h3>Input</h3>
          <div className="contents">

          </div>
        </div>
        <div className="numberinputs section" id="numberinputs">
          <h3>Number Input</h3>
          <div className="contents">

          </div>
        </div>
        <div className="radio section" id="radio">
          <h3>Radio</h3>
          <div className="contents">

          </div>
        </div>
        <div className="switch section" id="switch">
          <h3>Switch</h3>
          <div className="contents">

          </div>
        </div>
        <div className="selects section" id="selects">
          <h3>Select</h3>
          <div className="contents">

          </div>
        </div>

        <div className="treeselects section" id="treeselects">
          <h3>Tree Select</h3>
          <div className="contents">

          </div>
        </div>
      </div>

      <div className="data-display categories" id="data-display">
        <h3>Data Display</h3>

        <div className="avatars section" id="avatars">
          <h3>Avatars</h3>
          <div className="contents">

          </div>
        </div>
        <div className="badges section" id="badges">
          <h3>Badges</h3>
          <div className="contents">

          </div>
        </div>
        <div className="collapses section" id="collapses">
          <h3>Collapse</h3>
          <div className="contents">

          </div>
        </div>
        <div className="cards section" id="cards">
          <h3>Card</h3>
          <div className="contents">
            <div className="card" style={{ height: "150px", width: "50%" }}>
              Hellow iam card
            </div>
          </div>
        </div>
        <div className="calanders section" id="calanders">
          <h3>Calender</h3>
          <div className="contents">

          </div>
        </div>
        <div className="lists section" id="lists">
          <h3>List</h3>
          <div className="contents">

          </div>
        </div>
        <div className="trees section" id="trees">
          <h3>Tree</h3>
          <div className="contents">

          </div>
        </div>
        <div className="tooltips section" id="tooltips">
          <h3>Tooltip</h3>
          <div className="contents">

          </div>
        </div>
        <div className="tags section" id="tags">
          <h3>Tags</h3>
          <div className="contents">

          </div>
        </div>
        <div className="tables section" id="tables">
          <h3>Table</h3>
          <div className="contents">

          </div>
        </div>
        <div className="tabss section" id="tabss">
          <h3>Tabs</h3>
          <div className="contents">

          </div>
        </div>
      </div>

      <div className="feedback categories" id="feedback">
        <h3>Feedback</h3>

        <div className="alerts section" id="alerts">
          <h3>Alert</h3>
          <div className="contents">

          </div>
        </div>
        <div className="popup-modals section" id="popup-modals">
          <h3>Popup Model</h3>
          <div className="contents">

          </div>
        </div>
        <div className="popup-confirm section" id="popup-confirm">
          <h3>Popup-confirm</h3>
          <div className="contents">

          </div>
        </div>
        <div className="popup-action section" id="popup-action">
          <h3>Popup Action</h3>
          <div className="contents">

          </div>
        </div>
        <div className="notifications section" id="notifications">
          <h3>Notification</h3>
          <div className="contents">

          </div>
        </div>
        <div className="progresses section" id="progresses">
          <h3>Progress bar</h3>
          <div className="contents">

          </div>
        </div>
        <div className="spinners section" id="spinners">
          <h3>Spinner</h3>
          <div className="contents">

          </div>
        </div>
        <div className="skeletons section" id="skeletons">
          <h3>Skeleton</h3>
          <div className="contents">

          </div>
        </div>
      </div>

    </div>
  )
}
