import React from "react";
import { DiCss3, DiJavascript, DiNpm } from "react-icons/di";
import { FaList, FaRegFolder, FaRegFolderOpen } from "react-icons/fa";
import TreeView, { flattenTree } from "react-accessible-treeview";
import "./activitieslist.scss";
import { FcAddressBook, FcDepartment } from "react-icons/fc";
import { TbHelpCircle, TbFlask2, TbArrowsExchange, TbHome } from "react-icons/tb";


export type TreeNodeType = "account-section" | "models-section" | "reports-section" |
    "broker" | "real-account" | "test-account" |
    "activity" | "test-activity" |
    "test-section" | "backtest" | "forwardtest";


const folder = {
    name: "Accounts",
    children: [
        {
            name: "broker.Angelone",
            children: [
                {
                    name: "real-account.premkumaralaguraj@gmail.com",
                    children: [{ name: "index.js" }, { name: "styles.css" }],
                },
                {
                    name: "test-account.Test acc 1",
                    children: [{ name: "index.js" }, { name: "styles.css" }],
                }
            ],
        },
        {
            name: "broker.Binance",
            children: [
                {
                    name: "real-account.premkumaralaguraj@gmail.com",
                    children: [{ name: "index.js" }, { name: "styles.css" }],
                },
                {
                    name: "test-account.Test acc 1",
                    children: [{ name: "index.js" }, { name: "styles.css" }],
                },
                {
                    name: "test-account.Test acc 2",
                    children: [{ name: "index.js" }, { name: "styles.css" }],
                }
            ],
        },
        {
            name: "Strategies",
            children: [{ name: "index.js" }, { name: "styles.css" }],
        },
        {
            name: "Reports",
            children: [{ name: "index.js" }, { name: "styles.css" }],
        },
        {
            name: "node_modules",
            children: [
                {
                    name: "react-accessible-treeview",
                    children: [{ name: "index.js" }],
                },
                { name: "react", children: [{ name: "index.js" }] },
            ],
        }
    ],
};

const data = flattenTree(folder);

function ActivitiesList() {


    const resolveIcon = (name: string): JSX.Element => {
        let iconName = name.split('.')[0];
        switch (iconName) {
            case "account-section": return <FcAddressBook className="icon"/>;
            case "models-section": return <FcAddressBook className="icon"/>
            case "reports-section": return <FcAddressBook className="icon"/>
            case "broker": return <TbArrowsExchange className="icon" stroke="#a905b6"/>
            case "real-account": return <TbHome className="icon" stroke="#a905b6" fill="#aa05b650"/>
            case "test-account": return <TbFlask2 className="icon" stroke="#009900" fill="#00ff0084"/>
            case "activity": return <FcAddressBook className="icon"/>
            case "test-activity": return <FcAddressBook className="icon"/>
            case "test-section": return <FcAddressBook className="icon"/>
            case "backtest": return <FcAddressBook className="icon"/>
            case "forwardtest": return <FcAddressBook className="icon"/>
            default: return <TbHelpCircle className="icon" stroke="#ff9300" fill="#ff950057"/>
        }
    }
    return (
        <div>
            <div className="nav-tree-view">
                <TreeView
                    data={data}
                    aria-label="nav-tree-view tree"
                    nodeRenderer={({
                        element,
                        isBranch,
                        isExpanded,
                        getNodeProps,
                        level,
                    }) => (
                        <div {...getNodeProps()} style={{ paddingLeft: 20 * (level - 1) }}>
                            {
                                resolveIcon(element.name)
                            }

                            {element.name.substring(element.name.indexOf('.') + 1)}
                        </div>
                    )}
                />
            </div>
        </div>
    );
}

const FolderIcon = ({ isOpen }: { isOpen: boolean }) =>
    isOpen ? (
        <FaRegFolderOpen fill="#e8a87c" className="icon" />
    ) : (
        <FaRegFolder fill="#e8a87c" className="icon" />
    );

const FileIcon = ({ filename }: { filename: string }) => {
    const extension = filename.slice(filename.lastIndexOf(".") + 1);
    switch (extension) {
        case "js":
            return <DiJavascript fill="yellow" className="icon" />;
        case "css":
            return <DiCss3 fill="turquoise" className="icon" />;
        case "json":
            return <FaList fill="yellow" className="icon" />;
        case "npmignore":
            return <DiNpm fill="red" className="icon" />;
        default:
            return null;
    }
};

export default ActivitiesList;