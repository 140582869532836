import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store";

export type ThemeState = {
    isDarkMode : boolean
}

const initialState : ThemeState = {
    isDarkMode: window.localStorage.getItem('dark-mode') === 'true',
} 

export const themeSlice = createSlice({
    name : "theme",
    initialState,
    reducers : {
        changeMode : (state)=>{
            const app = document.getElementById('app');

            if(state.isDarkMode){
                app?.classList.toggle('dark-mode');
                window.localStorage.setItem('dark-mode', 'false')
                state.isDarkMode = false;
            } else {
                app?.classList.toggle('dark-mode');
                window.localStorage.setItem('dark-mode', 'true')
                state.isDarkMode = true;
            }
        }
    }
})

export const {changeMode} = themeSlice.actions;
export default themeSlice.reducer;
export const selectTheme = (state: RootState) => state.theme;