import Drawer from "./Drawer";
import NavBar from "./NavBar";
import ShellMenu from "./ShellMenu";
import TopBar from "./TopBar";
import './shell.scss';


type ShellProp = {
  children: JSX.Element;
}

export default function Shell({ children }: ShellProp) {
  return (
    <div className="shell">
      <ShellMenu />
      <NavBar />
      <div className="top_container">
        <TopBar />
        <div className="content">
          {children}
        </div>
        <Drawer />
      </div>
    </div>
  )
}
