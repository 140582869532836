import { useState } from "react"
import Icon from "../../components/icon/Icon"
import NewActivityDialog from "./NewActivityDialog"
import ActivitiesList from "./ActivitiesList";




function ActivitiesListx() {

    let list = ["Activity 1", "Activity 1", "Activity 1", "Activity 1", "Activity 1", "Activity 1", "Activity 1", "Activity 1", "Activity 1", "Activity 1", "Activity 1", "Activity 1"] 

    const listItems = list.map((ele, i) => i==2? (<div className="card active">{ele}</div>) : (<div className="card">{ele}</div>) );
    return (
    <div className="activities-list">
        {
            listItems   
        }
    </div>
  )
}


export function ActivitiesNavbar() {
    return (
        <div className="activities-nav">
            <div className="search-box">
                <div className="search-bar">
                    <button className="square"><Icon size="x-small">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="16" height="16" fill="white" fill-opacity="0.01" style={{ mixBlendMode: "multiply" }} />
                            <path d="M9 14H7C6.73478 14 6.48043 13.8946 6.29289 13.7071C6.10536 13.5196 6 13.2652 6 13V9.205L2.295 5.5C2.10721 5.31332 2.00112 5.05979 2 4.795V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H13C13.2652 2 13.5196 2.10536 13.7071 2.29289C13.8946 2.48043 14 2.73478 14 3V4.795C13.9989 5.05979 13.8928 5.31332 13.705 5.5L10 9.205V13C10 13.2652 9.89464 13.5196 9.70711 13.7071C9.51957 13.8946 9.26522 14 9 14ZM3 3V4.795L7 8.795V13H9V8.795L13 4.795V3H3Z" fill="#161616" />
                        </svg>
                    </Icon></button>
                    <input type="text" name="search" id="search" />
                    <button className="square"><Icon size="x-small">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="16" height="16" fill="white" fill-opacity="0.01" style={{ mixBlendMode: "multiply" }} />
                            <path d="M14.5 13.7929L10.7239 10.0169C11.6313 8.92758 12.0838 7.53038 11.9872 6.11596C11.8907 4.70153 11.2525 3.37879 10.2055 2.42288C9.15855 1.46698 7.78335 0.951515 6.366 0.983723C4.94865 1.01593 3.59828 1.59333 2.59581 2.59581C1.59333 3.59828 1.01593 4.94865 0.983723 6.366C0.951515 7.78335 1.46698 9.15855 2.42288 10.2055C3.37879 11.2525 4.70153 11.8907 6.11596 11.9872C7.53038 12.0838 8.92758 11.6313 10.0169 10.7239L13.7929 14.5L14.5 13.7929ZM2 6.5C2 5.60998 2.26392 4.73995 2.75838 3.99993C3.25285 3.25991 3.95565 2.68313 4.77792 2.34254C5.60019 2.00194 6.50499 1.91283 7.3779 2.08646C8.25082 2.2601 9.05264 2.68868 9.68198 3.31802C10.3113 3.94735 10.7399 4.74918 10.9135 5.62209C11.0872 6.495 10.998 7.3998 10.6575 8.22207C10.3169 9.04434 9.74008 9.74714 9.00006 10.2416C8.26004 10.7361 7.39001 11 6.5 11C5.30693 10.9987 4.1631 10.5241 3.31948 9.68052C2.47585 8.83689 2.00132 7.69306 2 6.5Z" fill="#161616" />
                        </svg>
                    </Icon></button></div>
                <div className="applied-filters">

                </div>
            </div>
            <ActivitiesList/>

        </div>
    )
}

export function ActivitiesNavHeader() {

    const [newActivity, setNewActivity] = useState(false)

    return (
        <><span>Activities</span><button onClick={() => setNewActivity(!newActivity)}><Icon size="x-small">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="16" height="16" fill="white" fill-opacity="0.01" style={{ mixBlendMode: "multiply" }} />
                <path d="M8 2C11.3 2 14 4.7 14 8C14 11.3 11.3 14 8 14C4.7 14 2 11.3 2 8C2 4.7 4.7 2 8 2ZM8 1C4.15 1 1 4.15 1 8C1 11.85 4.15 15 8 15C11.85 15 15 11.85 15 8C15 4.15 11.85 1 8 1Z" fill="#161616" />
                <path d="M12 7.5H8.5V4H7.5V7.5H4V8.5H7.5V12H8.5V8.5H12V7.5Z" fill="#161616" />
            </svg>
        </Icon></button>

            {
                newActivity ?
                    <NewActivityDialog/> : null
            }

        </>
    )
}

export function ActivitiesNavFooter() {
    return (
        <div>ActivitiesNavbar</div>
    )
}
