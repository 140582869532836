import './shell.scss';

export default function TopBar() {
  return (
    <div className="topbar">
      <div className='tools-group'>

      </div>
    </div>
  )
}
