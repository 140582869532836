import { useContext, useEffect, useState } from 'react';
import './shell.scss';
import { getSocket } from '../app/socket';

type ServiceComponents = {
    controller: { active: boolean, socket: boolean },
    activitymanager: { active: boolean, socket: boolean },
    configurator: { active: boolean, socket: boolean },
    useragent: { active: boolean, socket: boolean }
}

function ComponentStatus(props: { name: string, active: boolean, socket: boolean }) {
    var classList: string[] = ['service-status'];
    if (props.active) { classList.push("active") };
    if (props.socket) { classList.push("socket") };
    return (
        <div className={classList.join(' ')}>
            <p>{props.name}</p>
        </div>);
}

export default function SystemHealth() {
    const [sysInfo, setSysInfo] = useState({ cpu: 0, mem: 0 });

    const [serviceStatus, setServiceStatus] = useState<ServiceComponents>({
        controller: { active: false, socket: false },
        activitymanager: { active: false, socket: false },
        configurator: { active: false, socket: false },
        useragent: { active: false, socket: false }
    });

    useEffect(() => {

        let currentStatus = {
            controller: { active: false, socket: false },
            activitymanager: { active: false, socket: false },
            configurator: { active: false, socket: false },
            useragent: { active: false, socket: false }
        }

        getSocket('controller').then((sock) => {
            console.log("controller connected")
            console.log(`controller.id ${sock.id}`)
            console.log(`controller.active ${sock.active}`)
            console.log(`controller.connected ${sock.connected}`)
            console.log(`controller.disconnected ${sock.disconnected}`)
            console.log(`controller.volatile ${sock.volatile}`)

            if(sock.active){
                currentStatus.controller.socket = true;
            }
            // sock.emit("sysmon:start");
            // sock.on("sysmon:data", setSysInfo);
        })

        getSocket('activitymanager').then((sock) => {
            console.log("activitymanager connected")
            if(sock.active){
                currentStatus.activitymanager.socket = true;
            }
        })

        getSocket('configurator').then((sock) => {
            console.log("configurator connected")
            if(sock.active){
                currentStatus.configurator.socket = true;
            }
        })

        getSocket('useragent').then((sock) => {
            console.log("useragent connected")
            if(sock.active){
                currentStatus.useragent.socket = true;
            }
        })

        setServiceStatus(currentStatus)
        return () => {
            getSocket("controller").then((sock) => {
                console.log("emmiting sysmon:stop")
                sock.emit("sysmon:stop");
            })
        }
    }, [])

    return (
        <div className='systemhealth'>
            <div className="systemhealth-stats">
                <div className='block'>
                    <p>cpu</p>
                    <span> <div style={{ width: sysInfo.cpu + '%' }}></div></span>
                </div>
                <div className='block'>
                    <p>mem</p>
                    <span> <div style={{ width: sysInfo.mem + '%' }}></div></span>
                </div>
            </div>
            <div className="systemhealth-services">
                <ComponentStatus name='ctrl' active={serviceStatus.controller.active} socket={serviceStatus.controller.socket}  />
                <ComponentStatus name='activity' active={serviceStatus.activitymanager.active} socket={serviceStatus.activitymanager.socket} />
                <ComponentStatus name='config' active={serviceStatus.configurator.active} socket={serviceStatus.configurator.socket}  />
                <ComponentStatus name='user' active={serviceStatus.useragent.active} socket={serviceStatus.useragent.socket}  />
            </div>
        </div>
    )
}
