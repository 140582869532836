import { ReactNode } from 'react';
import { Intent } from '../../constants';
import './icon.scss'

export type IconProps = {
    varient?: "primary" | "active" | "disabled" | "hidden" | "display-none" | "blink" | null | undefined;
    size?: "x-small" | "small" | "medium" | "large" | "x-large" | undefined;
    color?: string | null | undefined;
    intent?: Intent | null | undefined;
    children: ReactNode;
};

export type MCIconProps = Pick<IconProps, "varient" | "size" | "intent" | "color">

export default function Icon(props: IconProps) {

    function buildClassList() {
        let classList = 'mc-icon';

        if (props.intent) {
            classList+=' '+props.intent+'-icon';
        }
        if (props.varient) {
            classList+=' '+props.varient+'-icon';
        }
        if (props.size) {
            classList+=' '+props.size+'-icon';
        }
        return classList;
    }

    return (
        <span className={buildClassList()}>
            {props.children}
        </span>
    )
}


export function ILogo(props: MCIconProps) {
    return (
        <Icon {...props}>
            <svg width="195" height="195" viewBox="0 0 195 195" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M166.443 166.443C152.807 180.079 135.434 189.365 116.521 193.127C97.6082 196.889 78.0042 194.958 60.1884 187.578C42.3726 180.199 27.1452 167.702 16.4317 151.668C5.71828 135.634 1.18766e-06 116.784 0 97.5C-1.18766e-06 78.2163 5.71827 59.3657 16.4317 43.3319C27.1451 27.2981 42.3726 14.8013 60.1884 7.42175C78.0042 0.0422036 97.6081 -1.88862 116.521 1.87343C135.434 5.63549 152.807 14.9215 166.443 28.5571L149.207 45.7928C138.98 35.5661 125.951 28.6016 111.766 25.7801C97.5811 22.9585 82.8781 24.4067 69.5163 29.9413C56.1544 35.476 44.7339 44.8486 36.6988 56.8739C28.6637 68.8993 24.375 83.0372 24.375 97.5C24.375 111.963 28.6637 126.101 36.6988 138.126C44.7339 150.151 56.1544 159.524 69.5163 165.059C82.8781 170.593 97.5811 172.041 111.766 169.22C125.951 166.398 138.98 159.434 149.207 149.207L166.443 166.443Z" fill="#009900" />
                <path d="M51.1932 131V61.1818H61.5568V72.0909H62.4659C63.9205 68.3636 66.2689 65.4697 69.5114 63.4091C72.7538 61.3182 76.6477 60.2727 81.1932 60.2727C85.7992 60.2727 89.6326 61.3182 92.6932 63.4091C95.7841 65.4697 98.1932 68.3636 99.9205 72.0909H100.648C102.436 68.4848 105.117 65.6212 108.693 63.5C112.269 61.3485 116.557 60.2727 121.557 60.2727C127.799 60.2727 132.905 62.2273 136.875 66.1364C140.845 70.0151 142.83 76.0606 142.83 84.2727V131H132.102V84.2727C132.102 79.1212 130.693 75.4394 127.875 73.2273C125.057 71.0151 121.739 69.9091 117.92 69.9091C113.011 69.9091 109.208 71.3939 106.511 74.3636C103.814 77.303 102.466 81.0303 102.466 85.5455V131H91.5568V83.1818C91.5568 79.2121 90.2689 76.0152 87.6932 73.5909C85.1174 71.1364 81.7992 69.9091 77.7386 69.9091C74.9508 69.9091 72.3447 70.6515 69.9205 72.1364C67.5265 73.6212 65.5871 75.6818 64.1023 78.3182C62.6477 80.9242 61.9205 83.9394 61.9205 87.3636V131H51.1932Z" fill="#009900" />
            </svg>
        </Icon>
    )
}