import './activities.scss'
import shellContext from "../../shell/ShellContext";
import Activity from "./Activity";
import { ActivitiesNavFooter, ActivitiesNavHeader, ActivitiesNavbar } from "./ActivitiesNavBar";

export default function ActivitiesPage() {

    shellContext.setNavContent(<ActivitiesNavbar />)
    shellContext.setNavHeader(<ActivitiesNavHeader />)
    shellContext.setNavFooter(<ActivitiesNavFooter />)

    return (
        <div className="activities">
            <Activity />
        </div>
    )
}