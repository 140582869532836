import { useEffect, useState } from 'react'
import './activity.scss';
import { getSocket } from '../../app/socket';
import React from 'react';


export function Info() {
    return (
        <div className='section info' data-title="Info">
            <table>
                <tr>
                    <td>Name</td>
                    <td><input type="text" value={"default..."} /></td>
                </tr>
                <tr>
                    <td>description</td>
                    <td><input type="text" value={"default..."} /></td>
                </tr>
                <tr>
                    <td>Tags</td>
                    <td><div className='tags_container'>
                        <div className='tag'>tag1</div>
                        <div className='tag'>tag2</div>
                        <div className='tag'>tag3</div>
                        <div className='tag'>tag4</div>
                        <div className='tag'>tag5</div>
                        <div className='tag'>tag6</div>
                        <div className='tag'>tag7</div>
                        <div className='tag'>tag8</div>
                    </div></td>
                </tr>
                <tr>
                    <td>Id</td>
                    <td>xx</td>
                </tr>
                <tr>
                    <td>Uptime</td>
                    <td>123</td>
                </tr>
            </table>
        </div>
    )
}

export function InputControl() {
    const [type, setType] = useState("random");
    const [fromValue, setFromValue] = useState(0);
    const [toValue, setToValue] = useState(0);
    const [frequency, setFrequency] = useState(0);
    const [rate, setRate] = useState(0);

    return (
        <div className="section input" data-title="Input Control">
            <div className="sub-section" data-title="Type">
                <div>
                    <input type="radio" name="type" id="random" value="random" radioGroup='input-type' checked={type === "random"} onChange={(e) => setType(e.target.value)} />
                    <label htmlFor="random">Random</label>
                </div>
                <div>
                    <input type="radio" name="type" id="sequence" value="sequence" radioGroup='input-type' checked={type === "sequence"} onChange={(e) => setType(e.target.value)} />
                    <label htmlFor="sequence">Sequence</label>
                </div>
            </div>
            <div className="sub-section" data-title="Control">
                {
                    type === "random" ? <div className='input'>
                        <div>Range</div>
                        <span>{fromValue}</span>
                        <input
                            type="range"
                            id="fromRange"
                            min="0"
                            max="100"
                            step="1"
                            value={fromValue}
                            onChange={(event) => {
                                const value = parseInt(event.target.value, 10);
                                setFromValue(value);
                            }}
                        />
                        <span>{toValue}</span>
                        <input
                            type="range"
                            id="toRange"
                            min="0"
                            max="100"
                            step="1"
                            value={toValue}
                            onChange={(event) => {
                                const value = parseInt(event.target.value, 10);
                                setToValue(value);
                            }}
                        />
                    </div>
                        :
                        <div className='input'>
                            <span>Rate</span>
                            <input type="number" name="rate" id="rate" value={rate}
                                onChange={(event) => {
                                    const value = Math.max(0, parseInt(event.target.value, 10) || 0);
                                    setRate(value);
                                }} />
                        </div>
                }

                <br />
                <br />
                <div className='input'>
                    <span>Throttle</span>
                    <input type="range" name="throttle" id="throttle" max={100} min={0} value={frequency}
                        onChange={(event) => {
                            const value = Math.max(0, parseInt(event.target.value, 10) || 0);
                            setFrequency(value);
                        }} />
                </div>
            </div>
        </div>)
}

export function SelectStrategy() {
    const [strategy, setStrategy] = useState("add");

    return (
        <div className="section strategy" data-title="Strategy">
            <div>
                <input type="radio" name="strategy" id="add" value="add" checked={strategy == "add"} onChange={(e) => setStrategy(e.target.value)} />
                <label htmlFor="random">add</label>
            </div>
            <div>
                <input type="radio" name="strategy" id="sub" value="sub" checked={strategy == "sub"} onChange={(e) => setStrategy(e.target.value)} />
                <label htmlFor="random">sub</label>

            </div>
            <div>
                <input type="radio" name="strategy" id="mul" value="mul" checked={strategy == "mul"} onChange={(e) => setStrategy(e.target.value)} />
                <label htmlFor="random">mul</label>

            </div>
            <div>
                <input type="radio" name="strategy" id="odd-even" value="odd-even" checked={strategy == "odd-even"} onChange={(e) => setStrategy(e.target.value)} />
                <label htmlFor="random">odd-even</label>

            </div>

            <div>
                <input type="radio" name="strategy" id="5divisible" value="5divisible" checked={strategy == "5divisible"} onChange={(e) => setStrategy(e.target.value)} />
                <label htmlFor="random">5div</label>

            </div>
            <div>
                <input type="radio" name="strategy" id="10div" value="10div" checked={strategy == "10div"} onChange={(e) => setStrategy(e.target.value)} />
                <label htmlFor="random">10div</label>

            </div>
            <div>
                <input type="radio" name="strategy" id="3div" value="3div" checked={strategy == "3div"} onChange={(e) => setStrategy(e.target.value)} />
                <label htmlFor="random">3div</label>

            </div>
        </div>)
}

export function ControlPannel() {
    return (
        <div className="section ctrl" data-title="ctrl">
            <button className='push-button'>stage</button>
            <button className='push-button'>commit</button>
            <button className='push-button'>start</button>
            <button className='push-button'>stop</button>
            <button className='push-button'>pause</button>
            <button className='push-button'>terminate</button>
        </div>
    )

}


export default function Activity() {

    const [timeSeriesData, setTimeSeriesData] = useState<any[]>([]);

    return (
            <div className='activity'>
                <div className='info-ctrl'>
                    <Info />
                    <ControlPannel />
                </div>
                <InputControl />
                <SelectStrategy />
                <div className="section output" data-title="Output">

                    <div className="telemetry">
                        {timeSeriesData.map((data, index) => (
                            <div key={index}>{data?.input?.random}</div>
                        ))}
                    </div>
                    <div className="telemetry">
                        {timeSeriesData.map((data, index) => (
                            <div key={index}>{data?.output}</div>
                        ))}
                    </div>

                    <div className="indicator-pannel">
                        <div className="indicator">
                            Odd ?
                        </div>
                        <div className="indicator green">
                            5 div
                        </div>
                        <div className="indicator red">
                            10 div
                        </div>
                        <div className="indicator">
                            3 div
                        </div>
                    </div>
                </div>
            </div>
    )
}
