import React from "react";
import { Socket, io } from "socket.io-client";

export type components = "controller" | "configurator" | "activitymanager" | "useragent";

var _socket: { [key: string]: null | Socket } =
{
    "controller": null,
    "configurator": null,
    "activitymanager": null,
    "useragent": null
};

var getToken: () => Promise<string>;

function setGetToken(getter: () => Promise<string>) {
    getToken = getter;
}

function _getSocket(name: components) {
    return _socket[name];
}

function _setSocket(name: components, socket: Socket) {
    return _socket[name] = socket;
}

async function getSocket(name: components): Promise<Socket> {
    return new Promise(async (resolve, reject) => {
        const socket = _getSocket(name);
        if (socket) {
            resolve(socket);
        } else {
            let conn = await initSocket(name, getToken);
            resolve(conn);
        }
    })
}

async function initSocket(name: components, getToken: () => Promise<string>): Promise<Socket> {
    console.log("initializing socket")
    let conn: Socket | undefined;
    let sockOptions = {
        // closeOnBeforeunload:true,
        // reconnectionDelay : 5000,
        // reconnectionDelayMax : 15000,
        // timeout:20000
    }
    return await new Promise((resolve, reject) =>
        getToken().then(
            (token) => {
                console.log(token);
                switch (name) {
                    case "controller":
                        conn = io(process.env.REACT_APP_WS || '', {
                            path: "/ctrl.socket",
                            extraHeaders: {
                                authorization: `Bearer ${token}`
                            },
                            ...sockOptions
                        });
                        break;
                    case "configurator":
                        conn = io(process.env.REACT_APP_WS || '', {
                            path: "/config.socket",
                            extraHeaders: {
                                authorization: `Bearer ${token}`
                            },
                            ...sockOptions
                        });
                        break;
                    case "activitymanager":
                        conn = io(process.env.REACT_APP_WS || '', {
                            path: "/activity.socket",
                            extraHeaders: {
                                authorization: `Bearer ${token}`
                            },
                            ...sockOptions
                        });
                        break;
                    case "useragent":
                        conn = io(process.env.REACT_APP_WS || '', {
                            path: "/user.socket",
                            extraHeaders: {
                                authorization: `Bearer ${token}`
                            },
                            ...sockOptions
                        });
                        break;
                }
                if(conn){
                    setInterval(()=>conn?.emit("ping"), 10000);
                }
                _setSocket(name, conn);
                resolve(conn);
            }
        ).catch((err) => {
            console.error("error while connecting socket")
        }).then(() => {
            conn?.on("connect", () => {
                console.log("Server connected : " + conn?.id);
            });
        })
    )
}

export { setGetToken, getSocket };
