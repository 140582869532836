export type JsxElementSetter = ((content: JSX.Element)=>void) | undefined;

export class ShellContext {

    private _setNavHeader : JsxElementSetter;
    private _setNavContent : JsxElementSetter;
    private _setNavFooter : JsxElementSetter;


    public setNavHeader(header : JSX.Element){
        if(this._setNavHeader){
            this._setNavHeader(header);
        } else {
            console.error("_setNavHeader is undefined");
        }
    }

    public setNavFooter(footer : JSX.Element){
        if(this._setNavFooter){
            this._setNavFooter(footer);
        } else {
            console.error("_setNavFooter is undefined");
        }
    }

    public setNavContent(content : JSX.Element){
        if(this._setNavContent){
            this._setNavContent(content);
        } else {
            console.error("_setNavContent is undefined");
        }
    }

    public registerNavStates(setNavHeader: JsxElementSetter, setNavContent: JsxElementSetter, setNavFooter: JsxElementSetter ){
        this._setNavHeader = setNavHeader;
        this._setNavContent = setNavContent;
        this._setNavFooter = setNavFooter;
    }
    
}

const shellContext = new ShellContext();
export default shellContext;
